<template>
    <div :class="`pt-1 vh-100 ${$isMobile() ? '' : 'container-lg'}`"> 

        <div v-if="!$isMobile()" class="h1 text-white fw-bolder mt-2">Chat List</div>
        <hr v-if="!$isMobile()" />

        <div v-if="chat_list.length < 1">
            <img src="@/static/undraw_Chatting_re_j55r.png" class="w-100" />
            <h2 class="text-white text-center">No Chat For Now</h2>
        </div>

        <div v-for="item in chat_list" class="">
            <router-link :to="`/chats/${item.user_id}`" class="d-flex align-items-center mx-2" style="gap:10px 20px;border-bottom: 2px dashed white;padding:1em 0">
                <div>
                    <img :src="item.user_picture ? item.user_picture : default_img"
                        class="rounded-circle" style="height:60px;width:60px;border:2px solid white" />
                </div>
                <div class="flex-fill">
                    <h3 class="text-white h4 mb-0 text-truncate" style="max-width:240px">{{ item.user_name }}</h3>
                    <h3 class="text-white h6 mb-0 text-truncate" style="max-width:240px;margin: .25em 0 !important;font-weight: normal;" v-if="!item.last_message.chat_file">{{ item.last_message.chat_text }}</h3>
                    <h3 class="text-white h6 mb-0" style="margin: .25em 0 !important;font-weight: normal;" v-else>File sent</h3>
                    <h3 class="text-white h6 mb-0" style="">
                        {{ moment(item.last_message.created_at).fromNow() }}
                    </h3>
                </div> 
            </router-link>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import moment from 'moment'

import Cookies from 'universal-cookie'
let cookies = new Cookies()

export default {
    computed: {
        chat_list(){
            return store.state.chat.CHAT_LIST?.sort(function(a, b) {
            var keyA = new Date(a.last_message.created_at),
                keyB = new Date(b.last_message.created_at);
            // Compare the 2 dates
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
            });
        }
    },
    mounted(){
        store.dispatch('chat/GetChatList', {
            source: cookies.get('token')
        })
    },
    data(){
        return {
            default_img : 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png',
            moment
        }
    }
}

</script>